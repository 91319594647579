/* eslint-disable import/prefer-default-export */

// Main Menu navigation
export const mainMenu = [
  {
    placeholder: "Menu",
    url: "/order-online",
    key: "menu",
  },
  {
    placeholder: "Order",
    key: "order",
    url: "",
    sublinks: [
      {
        placeholder: "Online",
        url: "/",
        key: "online",
      },
      {
        placeholder: "Delivery",
        url: "/",
        key: "delivery",
      },
      {
        placeholder: "Catering",
        url: "/",
        key: "catering",
      },
    ],
  },
  {
    placeholder: "Locations",
    url: "/find-a-nandos",
    key: "locations",
  },
  {
    placeholder: "Sauces",
    url: "/",
    key: "sauces",
  },
  {
    placeholder: "Rewards",
    url: "/",
    key: "rewards",
  },
  {
    placeholder: "Nutritional Calculator",
    url: "/",
    key: "nutritional-calculator",
  },
  {
    placeholder: "Gift Cards",
    url: "/",
    key: "gift-cards",
  },
  {
    placeholder: "Recipes",
    url: "/",
    key: "recipes",
  },
  {
    placeholder: "About Us",
    url: "/",
    key: "about-us",
  },
  {
    placeholder: "Work With Us",
    url: "/",
    key: "work-with-us",
  },
  {
    placeholder: "News",
    url: "/",
    key: "news",
  },
  {
    placeholder: "Help",
    url: "/",
    key: "help",
  },
]

// Footer Menu navigation
export const footerMenu = [
  {
    placeholder: "About Us",
    url: "/",
    key: "about-us",
  },
  {
    placeholder: "News",
    url: "/",
    key: "news",
  },
  {
    placeholder: "Help",
    url: "/",
    key: "help",
  },
  {
    placeholder: "Work with us",
    url: "/",
    key: "work-with-us",
  },
  {
    placeholder: "Terms of use",
    url: "/",
    key: "terms-of-use",
  },
  {
    placeholder: "Privacy policy",
    url: "/",
    key: "privacy-policy",
  },
]

// catalog of US states
export const US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

// catalog of CA states
export const CA_STATES = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon Territory",
}

// Restaurant Menu
export const restaurantMenu = [
  {
    id: "113786",
    categories: [
      {
        description: null,
        id: 41182,
        images: null,
        metadata: null,
        name: "Nando's Favorites",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "789",
            caloriesseparator: null,
            chainproductid: 297914,
            cost: 13.89,
            costoverridelabel: null,
            description:
              "You can't beat our signature 1/2 chicken.  Served with PERi chips to fully satisfy your craving or beat that hangover!",
            displayid: null,
            endhour: 24,
            id: 12806676,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "1/2 Chicken and Chips",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "840",
            caloriesseparator: null,
            chainproductid: 297990,
            cost: 10.39,
            costoverridelabel: null,
            description:
              "Portuguese rice topped with PERi-PERi chicken, arugula, roasted red peppers, cut grilled corn and hummus. (787 cal)",
            displayid: null,
            endhour: 24,
            id: 12806678,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-PERi Chicken Tender Bowl",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "880",
            caloriesseparator: null,
            chainproductid: 297846,
            cost: 9.99,
            costoverridelabel: null,
            description:
              "Avocado, roasted red peppers, cut grilled corn, hummus and arugula served over Portuguese rice. (775 cal)",
            displayid: null,
            endhour: 24,
            id: 12806680,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Avocado & Roasted Pepper Bowl",
            quantityincrement: "1",
            shortdescription: "Avocado & Pepper Bowl",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description:
          "What we're famous for... Fresh chicken marinated for at least 24 hours in PERi-PERi sauce flame-grilled to order.",
        id: 41179,
        images: null,
        metadata: null,
        name: "PERi-PERi Chicken",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "423",
            caloriesseparator: "-",
            chainproductid: 298039,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "First time at Nando's? Look no further than our signature 1/2 chicken. (423 cal)",
            displayid: null,
            endhour: 24,
            id: 12806682,
            imagefilename: null,
            images: null,
            maxcalories: "1623",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "1/2 Chicken",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "226",
            caloriesseparator: "-",
            chainproductid: 297994,
            cost: 0.0,
            costoverridelabel: null,
            description: "Double your favorite cut. (326 cal)",
            displayid: null,
            endhour: 24,
            id: 12806684,
            imagefilename: null,
            images: null,
            maxcalories: "1426",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Two 1/4 Chicken Legs",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "260",
            caloriesseparator: "-",
            chainproductid: 297989,
            cost: 0.0,
            costoverridelabel: null,
            description: "The succulent and saucy bits. (260 cal)",
            displayid: null,
            endhour: 24,
            id: 12806686,
            imagefilename: null,
            images: null,
            maxcalories: "1460",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "1/4 Chicken Breast",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "163",
            caloriesseparator: "-",
            chainproductid: 297923,
            cost: 0.0,
            costoverridelabel: null,
            description: "For a little dark 'n' delicious. (163 cal)",
            displayid: null,
            endhour: 24,
            id: 12806688,
            imagefilename: null,
            images: null,
            maxcalories: "1363",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "1/4 Chicken Leg",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "260",
            caloriesseparator: "-",
            chainproductid: 298102,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Butterflied whole chicken breast with crispy skin. No bone about it! (429 cal)",
            displayid: null,
            endhour: 24,
            id: 12806690,
            imagefilename: null,
            images: null,
            maxcalories: "1460",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Boneless Breast",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "175",
            caloriesseparator: "-",
            chainproductid: 298005,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Dig into 3 boneless thighs, flame-grilled with skin. (418 cal)",
            displayid: null,
            endhour: 24,
            id: 12806692,
            imagefilename: null,
            images: null,
            maxcalories: "1275",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "3 Chicken Thighs",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "510",
            caloriesseparator: "-",
            chainproductid: 297808,
            cost: 0.0,
            costoverridelabel: null,
            description: "Two of the succulent and saucy bits. (520 cal)",
            displayid: null,
            endhour: 24,
            id: 12806694,
            imagefilename: null,
            images: null,
            maxcalories: "1710",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Two 1/4 Chicken Breasts",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "809",
            caloriesseparator: "-",
            chainproductid: 298085,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Twelve of our sauciest treats - tender and spicy. (806 cal)",
            displayid: null,
            endhour: 24,
            id: 12806696,
            imagefilename: null,
            images: null,
            maxcalories: "2009",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "12 Wings",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "310",
            caloriesseparator: "-",
            chainproductid: 297889,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Two skewers of skin-on-thighs, red pepper, and onions. (310 cal)",
            displayid: null,
            endhour: 24,
            id: 12806698,
            imagefilename: null,
            images: null,
            maxcalories: "1510",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Thigh Skewers",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "413",
            caloriesseparator: "-",
            chainproductid: 298029,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Rich, tasty livers that melt in your mouth. (413 cal)",
            displayid: null,
            endhour: 24,
            id: 12806700,
            imagefilename: null,
            images: null,
            maxcalories: "1613",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Livers",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: "Sandwiches, Wraps & Pitas",
        id: 41175,
        images: null,
        metadata: null,
        name: "Sandwiches, Wraps & Pitas",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "1003",
            caloriesseparator: "-",
            chainproductid: 297809,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "A butterflied PERi-PERi chicken breast served on garlic bread, and topped with our house-made coleslaw. (1003 cal)",
            displayid: null,
            endhour: 24,
            id: 12806702,
            imagefilename: null,
            images: null,
            maxcalories: "2203",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Nandocas' Choice",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "523",
            caloriesseparator: "-",
            chainproductid: 297842,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Two boneless thighs with arugula, tomato, pickled red onions and PERinaise. (523 cal)",
            displayid: null,
            endhour: 24,
            id: 12806704,
            imagefilename: null,
            images: null,
            maxcalories: "1723",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "The Thigh & Mighty Sandwich",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "609",
            caloriesseparator: "-",
            chainproductid: 297929,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our chicken sandwich taken to the next level with pineapple and cheddar cheese -trust us on this one! (606 cal)",
            displayid: null,
            endhour: 24,
            id: 12806706,
            imagefilename: null,
            images: null,
            maxcalories: "1809",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: 'The Chicken "Burger"',
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "454",
            caloriesseparator: "-",
            chainproductid: 297867,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Served with arugula, tomato, pickled onions and PERinaise. (471 cal)",
            displayid: null,
            endhour: 24,
            id: 12806708,
            imagefilename: null,
            images: null,
            maxcalories: "1654",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Breast Sandwich",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "544",
            caloriesseparator: "-",
            chainproductid: 298059,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Served with a mix of crunchy vegetables, sweet chilli jam and tangy yogurt sauce. (538 cal)",
            displayid: null,
            endhour: 24,
            id: 12806710,
            imagefilename: null,
            images: null,
            maxcalories: "1744",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Breast Wrap",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "544",
            caloriesseparator: "-",
            chainproductid: 298018,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Served toasted with a mix of lettuce and crunchy vegetables with PERinaise. (426 cal)",
            displayid: null,
            endhour: 24,
            id: 12806712,
            imagefilename: null,
            images: null,
            maxcalories: "1744",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Breast Pita",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "606",
            caloriesseparator: "-",
            chainproductid: 298119,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our chicken Caesar salad with PERi-dried tomatoes in a toasted wrap. (761 cal)",
            displayid: null,
            endhour: 24,
            id: 12806714,
            imagefilename: null,
            images: null,
            maxcalories: "1806",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Caesar Wrap",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "506",
            caloriesseparator: "-",
            chainproductid: 297810,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our chicken Caesar salad with PERi-dried tomatoes in a toasted pita. (761 cal)",
            displayid: null,
            endhour: 24,
            id: 12806716,
            imagefilename: null,
            images: null,
            maxcalories: "1706",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Caesar Pita",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "640",
            caloriesseparator: "-",
            chainproductid: 297802,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula and chilli jam in a toasted Portuguese roll. (632 cal)",
            displayid: null,
            endhour: 24,
            id: 12806718,
            imagefilename: null,
            images: null,
            maxcalories: "1840",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portobello Mushroom & Halloumi Sandwich",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "640",
            caloriesseparator: "-",
            chainproductid: 298084,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula and chilli jam in a toasted wrap. (632 cal)",
            displayid: null,
            endhour: 24,
            id: 12806720,
            imagefilename: null,
            images: null,
            maxcalories: "1840",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portobello Mushroom & Halloumi Wrap",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "600",
            caloriesseparator: "-",
            chainproductid: 297939,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula and chilli jam in a toasted pita. (632 cal)",
            displayid: null,
            endhour: 24,
            id: 12806722,
            imagefilename: null,
            images: null,
            maxcalories: "1800",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portobello Mushroom & Halloumi Pita",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "899",
            caloriesseparator: "-",
            chainproductid: 298105,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula and chilli jam in a toasted Portuguese roll. (632 cal)",
            displayid: null,
            endhour: 24,
            id: 12806724,
            imagefilename: null,
            images: null,
            maxcalories: "2099",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Feta, Avocado & Grains Wrap",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "547",
            caloriesseparator: "-",
            chainproductid: 298095,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Plant-based patty made from piquante peppers, mushrooms, water chestnuts and garden vegetables topped with arugula, tomato, pickled onions and PERinaise. (533 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806726,
            imagefilename: null,
            images: null,
            maxcalories: "1747",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Veggie Burger",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "640",
            caloriesseparator: "-",
            chainproductid: 297938,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula, yogurt sauce Grilled sweet potatoes served with arugula, yogurt sauce and chilli jam. Also available on a Portuguese roll or in a pita. (690 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806728,
            imagefilename: null,
            images: null,
            maxcalories: "1840",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Sweet Potato Halloumi Pita",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "640",
            caloriesseparator: "-",
            chainproductid: 297942,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula, yogurt sauce Grilled sweet potatoes served with arugula, yogurt sauce and chilli jam. Also available on a Portuguese roll or in a pita. (690 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806730,
            imagefilename: null,
            images: null,
            maxcalories: "1840",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Sweet Potato Halloumi Wrap",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "640",
            caloriesseparator: "-",
            chainproductid: 297760,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Roasted and served with arugula, yogurt sauce Grilled sweet potatoes served with arugula, yogurt sauce and chilli jam. Also available on a Portuguese roll or in a pita. (690 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806732,
            imagefilename: null,
            images: null,
            maxcalories: "1840",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Sweet Potato Halloumi Sandwich",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description:
          "Sharing embodies the true spirit of Nando's PERi-PERi - great heaps of food and good messy fun. Roll up your sleeves and enjoy!",
        id: 41177,
        images: null,
        metadata: null,
        name: "Platters",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298046,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Finally, 2020 delivers! Eat your feelings this holiday season with an order of Garlic Sticks, a Whole Flame-Grilled PERi-PERi Chicken, Brussels Sprouts*, 1 Large Side of choice, 4 Nata's, and a bottle of PERi-PERi Sauce of choice.\r\n\r\nPlease select a PERi-PERi Flavor, Two Large Sides, and Bottled Sauce of choice - Garlic Sticks and Natas will come with the order by default.\r\n\r\n(Brussels Sprouts can be substituted for one Large Side of Choice)",
            displayid: null,
            endhour: 24,
            id: 12806734,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Eat Your Feelings Platter",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297800,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Two whole chickens plus 2 extra-large sides. (1642 cal)",
            displayid: null,
            endhour: 24,
            id: 12806736,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Jumbo Chicken Platter",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298106,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Whole chicken plus your choice of 2 large sides. (846 cal)",
            displayid: null,
            endhour: 24,
            id: 12806738,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Full Chicken Platter",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297793,
            cost: 23.99,
            costoverridelabel: null,
            description: "24 flame-grilled PERi-PERi wings. (1612 cal)",
            displayid: null,
            endhour: 24,
            id: 12806740,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Wing Platter",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298122,
            cost: 28.19,
            costoverridelabel: null,
            description:
              "24 flame-grilled PERi-PERi wings plus large Peri Chips. (2359 cal)",
            displayid: null,
            endhour: 24,
            id: 12806742,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Wing & Chip Platter",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41208,
        images: null,
        metadata: null,
        name: "Salads & Bowls",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "745",
            caloriesseparator: null,
            chainproductid: 297904,
            cost: 10.39,
            costoverridelabel: null,
            description:
              "Chopped romaine tossed in our house Caesar dressing. Topped with PERi-PERi chicken, shaved parmesan, house-made croutons, black pepper, and PERi-dried tomatoes. (803 cal) ",
            displayid: null,
            endhour: 24,
            id: 12806744,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken Caesar Salad",
            quantityincrement: "1",
            shortdescription: "Chicken Caesar Salad",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297822,
            cost: 11.29,
            costoverridelabel: null,
            description:
              "Romaine and arugula in a balsamic vinaigrette. Topped with PERi chicken, marinated grape tomatoes, grilled halloumi cheese, grilled corn and house-made croutons. (587 cal)",
            displayid: null,
            endhour: 24,
            id: 12806746,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Marinated Tomato, Halloumi & Chicken Salad",
            quantityincrement: "1",
            shortdescription: "Heirloom, Halloumi & Chicken Salad",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297852,
            cost: 9.99,
            costoverridelabel: null,
            description:
              "Avocado, roasted red peppers, cut grilled corn, hummus and arugula served over Portuguese rice. (775 cal)",
            displayid: null,
            endhour: 24,
            id: 12806752,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Avocado & Roasted Pepper Bowl",
            quantityincrement: "1",
            shortdescription: "Avocado & Pepper Bowl",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298025,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Portuguese rice topped with PERi-PERi chicken, arugula, roasted red peppers, cut grilled corn and hummus. (787 cal)",
            displayid: null,
            endhour: 24,
            id: 12806750,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chicken, Avo & Feta Bowl",
            quantityincrement: "1",
            shortdescription: "Feta, Avo & Chicken Bowl",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297843,
            cost: 10.99,
            costoverridelabel: null,
            description:
              "Grilled peaches, arugula, roasted red peppers, PERi-PERi chicken tenders served over Portuguese rice. (660 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806748,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Everything's Peachy Bowl",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description:
          "Prepare your taste buds for the main event with mouthwatering nibbles.",
        id: 41203,
        images: null,
        metadata: null,
        name: "Snacks & Shareables",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "403",
            caloriesseparator: null,
            chainproductid: 298078,
            cost: 6.69,
            costoverridelabel: null,
            description:
              "Six of our star treats. Marinated for 24 hours and flame-grilled to order.",
            displayid: null,
            endhour: 24,
            id: 12805151,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-PERi Wings",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "760",
            caloriesseparator: "-",
            chainproductid: 297761,
            cost: 6.25,
            costoverridelabel: null,
            description:
              "PERi-PERi-infused oil poured over creamy hummus. Dig in with toasted pita or crunchy seasonal vegetables.",
            displayid: null,
            endhour: 24,
            id: 12806754,
            imagefilename: null,
            images: null,
            maxcalories: "1194",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Hummus with PERi-PERi Drizzle",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "186",
            caloriesseparator: null,
            chainproductid: 297870,
            cost: 4.79,
            costoverridelabel: null,
            description:
              "Green and black olives perked up with garlic, peppers and chilli. (May contain the occasional olive pit.)",
            displayid: null,
            endhour: 24,
            id: 12806756,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Spicy Mixed Olives",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "738",
            caloriesseparator: null,
            chainproductid: 297860,
            cost: 4.49,
            costoverridelabel: null,
            description: "Almonds, cashews, macadamias - crunch with a punch.",
            displayid: null,
            endhour: 24,
            id: 12806758,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-PERi Nuts",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "852",
            caloriesseparator: null,
            chainproductid: 297813,
            cost: 4.49,
            costoverridelabel: null,
            description:
              "Baked Portuguese roll, cut in strips, and smothered in garlic-herb spread and grilled. ",
            displayid: null,
            endhour: 24,
            id: 12806760,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Garlic Sticks",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41199,
        images: null,
        metadata: null,
        name: "Sides",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297779,
            cost: 4.5,
            costoverridelabel: null,
            description:
              "Brussel Sprouts braised with red onion, chilis\r\nand garlic. (309 cal)",
            displayid: null,
            endhour: 24,
            id: 12806786,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Braised Brussels Sprouts",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "57",
            caloriesseparator: null,
            chainproductid: 298123,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Fresh mixed greens, peppers, cucumbers, and PERi-dried tomatoes with a side of balsamic dressing. (123 cal)",
            displayid: null,
            endhour: 24,
            id: 12806762,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Mixed Leaf Side Salad",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "274",
            caloriesseparator: null,
            chainproductid: 297907,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Romaine lettuce tossed with our house Caesar, batch made croutons, shaved parmesan cheese, and freshly ground black pepper. (297 cal)",
            displayid: null,
            endhour: 24,
            id: 12806764,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Caesar Side Salad",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "189",
            caloriesseparator: null,
            chainproductid: 297803,
            cost: 3.99,
            costoverridelabel: null,
            description:
              "Cucumbers and pickled onions tossed in a poppy seed dressing. (216 cal)",
            displayid: null,
            endhour: 24,
            id: 12806766,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Cucumber & Poppy Seed Salad",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "118",
            caloriesseparator: null,
            chainproductid: 297919,
            cost: 3.99,
            costoverridelabel: null,
            description:
              "Roasted zucchini, chunky peppers and red onions tossed in a poppy seed dressing. (118 cal)",
            displayid: null,
            endhour: 24,
            id: 12806768,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Seasonal Roasted Veggies",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "373",
            caloriesseparator: null,
            chainproductid: 297792,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Crispy fries with our signature PERi-salt and side of PERinaise. (649 cal)",
            displayid: null,
            endhour: 24,
            id: 12806770,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi Chips with PERinaise",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "325",
            caloriesseparator: null,
            chainproductid: 297880,
            cost: 0.0,
            costoverridelabel: null,
            description: "Crispy fries with our signature PERi-salt. (373 cal)",
            displayid: null,
            endhour: 24,
            id: 12806772,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi Chips",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "283",
            caloriesseparator: "-",
            chainproductid: 297928,
            cost: 0.0,
            costoverridelabel: null,
            description: "House-made with turmeric and peppers. (283 cal)",
            displayid: null,
            endhour: 24,
            id: 12806774,
            imagefilename: null,
            images: null,
            maxcalories: "565",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portuguese Rice",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "398",
            caloriesseparator: "-",
            chainproductid: 297773,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Baked Portuguese roll smothered in garlic-herb butter and grilled. (398 cal)",
            displayid: null,
            endhour: 24,
            id: 12806776,
            imagefilename: null,
            images: null,
            maxcalories: "796",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Garlic Bread",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "262",
            caloriesseparator: "-",
            chainproductid: 298108,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Creamy mashed potatoes with real butter and garlic. (262 cal)",
            displayid: null,
            endhour: 24,
            id: 12806778,
            imagefilename: null,
            images: null,
            maxcalories: "459",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Red Skin Mashed Potatoes",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "168",
            caloriesseparator: "-",
            chainproductid: 297911,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "House-made crunchy slaw with onion and carrots in a creamy mayo dressing. (168 cal)",
            displayid: null,
            endhour: 24,
            id: 12806780,
            imagefilename: null,
            images: null,
            maxcalories: "393",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Coleslaw",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "179",
            caloriesseparator: "-",
            chainproductid: 297777,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Fresh yellow corn flame-grilled and served with butter. (90 cal)",
            displayid: null,
            endhour: 24,
            id: 12806782,
            imagefilename: null,
            images: null,
            maxcalories: "213",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Corn on the Cob",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "328",
            caloriesseparator: "-",
            chainproductid: 298063,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Rugged mash of whole peas, parsley, mint and chili. (328 cal)",
            displayid: null,
            endhour: 24,
            id: 12806784,
            imagefilename: null,
            images: null,
            maxcalories: "656",
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Macho Peas",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298083,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Yellow like the South African sun.  Florets roasted with turmeric.  (120 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806788,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Turmeric Cauliflower",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297890,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Farm fresh sweet potato slices cooked on our famous grill.  Want 'em sweeter?  Add cinnamon sugar.  (120 Cal)",
            displayid: null,
            endhour: 24,
            id: 12806790,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Charred Sweet Potato",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41180,
        images: null,
        metadata: null,
        name: "Drinks",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "0",
            caloriesseparator: null,
            chainproductid: 298028,
            cost: 3.29,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806792,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Smart Water",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "150",
            caloriesseparator: null,
            chainproductid: 298048,
            cost: 3.29,
            costoverridelabel: null,
            description: "Mexican Coke- 355 ml (150 cal)",
            displayid: null,
            endhour: 24,
            id: 12806794,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Mexican Coke",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "150",
            caloriesseparator: null,
            chainproductid: 297893,
            cost: 2.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806796,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Coke Classic",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "0",
            caloriesseparator: null,
            chainproductid: 297833,
            cost: 2.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806798,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Diet Coke Classic",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "0",
            caloriesseparator: null,
            chainproductid: 297963,
            cost: 2.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806800,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Coke Zero",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "150",
            caloriesseparator: null,
            chainproductid: 297957,
            cost: 2.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806802,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Sprite",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "70",
            caloriesseparator: null,
            chainproductid: 297831,
            cost: 2.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806804,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Honest - Honey Green Tea",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "128",
            caloriesseparator: null,
            chainproductid: 298117,
            cost: 3.39,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806806,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Pineapple Lemonade",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "160",
            caloriesseparator: null,
            chainproductid: 297998,
            cost: 3.39,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806808,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Mango Orange Lemonade",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "165",
            caloriesseparator: null,
            chainproductid: 297782,
            cost: 3.29,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806812,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bundaberg Ginger Beer",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "0",
            caloriesseparator: null,
            chainproductid: 297920,
            cost: 2.59,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806814,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "LaCroix-Grapefruit",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "0",
            caloriesseparator: null,
            chainproductid: 298016,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806816,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Tap Water",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [
              "pickup",
              "curbside",
              "delivery",
              "dispatch",
            ],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297970,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806818,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Pepsi",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297999,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806820,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Mountain Dew",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297859,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806810,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Pepsi Zero",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297823,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806822,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Diet Dr. Pepper",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297898,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806824,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Crush Orange",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297943,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806826,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Dole Lemonade",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297953,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806828,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bubly Blackberry",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297987,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806844,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bubly Cherry",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298045,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806842,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Pure Leaf (Sweet)",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298120,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806840,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Lipton Pure Leaf (Unsweet)",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297945,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806838,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "LifeWtr",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297901,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806836,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Aquafina",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298033,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806834,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Voss Still Water",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297776,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806832,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Perrier Sparkling Water",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297854,
            cost: 3.29,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806830,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bruce Cost Ginger Ale- Pomegranate",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41190,
        images: null,
        metadata: null,
        name: "Desserts",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "930",
            caloriesseparator: null,
            chainproductid: 297752,
            cost: 6.69,
            costoverridelabel: null,
            description:
              "Four rich, golden layers of cake with walnuts, pineapple and raisins. Topped with a smooth cream cheese icing.",
            displayid: null,
            endhour: 24,
            id: 12806846,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Carrot Cake",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298009,
            cost: 6.69,
            costoverridelabel: null,
            description:
              "Raspberry sauce swirled through a creamy white chocolate cheesecake. Lightly caramelized on top.",
            displayid: null,
            endhour: 24,
            id: 12806848,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Raspberry Cheesecake ",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "210",
            caloriesseparator: null,
            chainproductid: 297828,
            cost: 2.79,
            costoverridelabel: null,
            description:
              "Traditional Portuguese custard tart, served warm with a sprinkle of cinnamon sugar.",
            displayid: null,
            endhour: 24,
            id: 12806850,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Naughty Natas",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "210",
            caloriesseparator: null,
            chainproductid: 298060,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our version of the perfect brownie – indulgent, fudgey goodness!",
            displayid: null,
            endhour: 24,
            id: 12806852,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Barely Baked Brownie",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297821,
            cost: 3.09,
            costoverridelabel: null,
            description: "Ice cream in Portuguese. Light & creamy. ",
            displayid: null,
            endhour: 24,
            id: 12806854,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chocolate Gelado",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297784,
            cost: 3.09,
            costoverridelabel: null,
            description: "Ice cream in Portuguese. Light & creamy. ",
            displayid: null,
            endhour: 24,
            id: 12806856,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Vanilla Gelado",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298020,
            cost: 3.09,
            costoverridelabel: null,
            description: "Ice cream in Portuguese. Light & creamy. ",
            displayid: null,
            endhour: 24,
            id: 12806858,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Mango Gelado",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: "Nando's Kids Meals",
        id: 41178,
        images: null,
        metadata: null,
        name: "Nandino's",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "201",
            caloriesseparator: null,
            chainproductid: 297961,
            cost: 0.0,
            costoverridelabel: null,
            description: "One of our star treats- tender and juicy.",
            displayid: null,
            endhour: 24,
            id: 12806860,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "3 Chicken Wings & 1 side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "148",
            caloriesseparator: null,
            chainproductid: 298077,
            cost: 0.0,
            costoverridelabel: null,
            description: "Chicken breast, sliced and grilled.",
            displayid: null,
            endhour: 24,
            id: 12806862,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Grilled Chicken Breast strips & 1 side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "367",
            caloriesseparator: null,
            chainproductid: 297885,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "On a Portuguese roll, topped with lettuce and tomato.",
            displayid: null,
            endhour: 24,
            id: 12806864,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Nandino's Chicken Sandwich & 1 side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "203",
            caloriesseparator: null,
            chainproductid: 298007,
            cost: 0.0,
            costoverridelabel: null,
            description: "Our signature chicken on the bone",
            displayid: null,
            endhour: 24,
            id: 12806866,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Grilled Drumstick & Thigh & 1 side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "480",
            caloriesseparator: null,
            chainproductid: 297849,
            cost: 0.0,
            costoverridelabel: null,
            description: "Melted cheddar cheese on a toasted white bread.",
            displayid: null,
            endhour: 24,
            id: 12806868,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Nandino's Grilled Cheese & 1 side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41191,
        images: null,
        metadata: null,
        name: "Extras",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "150",
            caloriesseparator: null,
            chainproductid: 298130,
            cost: 2.59,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806870,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Halloumi Cheese Side",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "62",
            caloriesseparator: null,
            chainproductid: 297986,
            cost: 2.29,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806872,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portobello Mushroom",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "50",
            caloriesseparator: null,
            chainproductid: 298054,
            cost: 1.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806874,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-naise",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "30",
            caloriesseparator: null,
            chainproductid: 297960,
            cost: 0.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806876,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Cheddar Cheese",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "60",
            caloriesseparator: null,
            chainproductid: 297862,
            cost: 0.79,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806878,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Pineapple Slice",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "15",
            caloriesseparator: null,
            chainproductid: 297882,
            cost: 0.95,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806880,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Chilli Jam",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "365",
            caloriesseparator: null,
            chainproductid: 298111,
            cost: 1.95,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806882,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Portuguese Roll",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "35",
            caloriesseparator: null,
            chainproductid: 298052,
            cost: 0.75,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806884,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Hummus 1oz Scoop",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "136",
            caloriesseparator: null,
            chainproductid: 297820,
            cost: 1.59,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806886,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Half Avocado",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 298121,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806888,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Extra Extra Hot Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: ["delivery"],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297785,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806890,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Extra Hot Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: ["delivery"],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297902,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806892,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Hot Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297878,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806894,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Garlic Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297855,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806896,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Medium Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297844,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806898,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Wild Herb Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainproductid: 297959,
            cost: 0.0,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806900,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: "5",
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Lemon & Herb Sauce Container",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "159",
            caloriesseparator: null,
            chainproductid: 297768,
            cost: 2.09,
            costoverridelabel: null,
            description: "",
            displayid: null,
            endhour: 24,
            id: 12806902,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-PERi Drizzle",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
      {
        description: null,
        id: 41187,
        images: null,
        metadata: null,
        name: "Sauce Bottles",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 298070,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our PERi-PERi sauce is made with African Bird's eye Chilli and a blend of ingredients-onion, sun-ripened lemons, garlic and spices.  ",
            displayid: null,
            endhour: 24,
            id: 12806904,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce Lemon Herb",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 298022,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Our PERi-PERi sauce is made with African Bird's eye Chilli and a blend of ingredients-onion, sun-ripened lemons, garlic and spices.  This one has a dash of creamy, smooth coconut milk and a zesty twist of lemon.  PERi-PERi is the heart & soul of Nando's and the secret ingredient behind our find worldwide.",
            displayid: null,
            endhour: 24,
            id: 12806906,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce Coconut Lemon",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 297896,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "Garlic is many things, but it isn't subtle.  Just like this sauce, a blend of PERi-PERi with its sweet waves of garlicky goodness.  If you're on a first date, pour this on both of your plates.  You'll thank us later.",
            displayid: null,
            endhour: 24,
            id: 12806908,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce Garlic",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 297778,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "This is our gateway sauce: a good dollop of PERi-PERi with spices.  For a while, it will be enough.  But soon you'll want them all.  Just go with it.",
            displayid: null,
            endhour: 24,
            id: 12806910,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce Medium",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 297872,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "If you're after a bite of heat, you've come to the right sauce.  Bursting with PERi-PERi, a kick of garlic and lemon, this is how hardcore PERi-PERi fans get caught in the heat of the moment.",
            displayid: null,
            endhour: 24,
            id: 12806912,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce Hot",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "26",
            caloriesseparator: null,
            chainproductid: 297762,
            cost: 0.0,
            costoverridelabel: null,
            description:
              "We salute you.  With eyes streaming and mouth burning, you'll fall for fire that doesn't flinch from flavor.  Balanced with PERi-PERi, lemon, garlic, onion and spices, you will be coming back for more.",
            displayid: null,
            endhour: 24,
            id: 12806914,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle Sauce XX Hot",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "276",
            caloriesseparator: null,
            chainproductid: 297966,
            cost: 3.99,
            costoverridelabel: null,
            description:
              "Our great tasting and famous PERi-PERi blended mayo that you've learnt to love in our find.",
            displayid: null,
            endhour: 24,
            id: 12806916,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle PERinaise Original",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "276",
            caloriesseparator: null,
            chainproductid: 298066,
            cost: 3.99,
            costoverridelabel: null,
            description:
              "Hot PERinaise is the perfect blend of the creaminess of mayonnaise and the spiciness of PERi-PERi.",
            displayid: null,
            endhour: 24,
            id: 12806918,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Bottle PERinaise Hot",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
    ],
    imagepath: null,
  },
  {
    id: "115036",
    categories: [
      {
        description: null,
        id: 41182,
        images: null,
        metadata: null,
        name: "Nando's Favorites",
        products: [
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "789",
            caloriesseparator: null,
            chainproductid: 297914,
            cost: 13.89,
            costoverridelabel: null,
            description:
              "You can't beat our signature 1/2 chicken.  Served with PERi chips to fully satisfy your craving or beat that hangover!",
            displayid: null,
            endhour: 24,
            id: 12806676,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "1/2 Chicken and Chips",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "840",
            caloriesseparator: null,
            chainproductid: 297990,
            cost: 10.39,
            costoverridelabel: null,
            description:
              "Portuguese rice topped with PERi-PERi chicken, arugula, roasted red peppers, cut grilled corn and hummus. (787 cal)",
            displayid: null,
            endhour: 24,
            id: 12806678,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "PERi-PERi Chicken Tender Bowl",
            quantityincrement: "1",
            shortdescription: "",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
          {
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: "880",
            caloriesseparator: null,
            chainproductid: 297846,
            cost: 9.99,
            costoverridelabel: null,
            description:
              "Avocado, roasted red peppers, cut grilled corn, hummus and arugula served over Portuguese rice. (775 cal)",
            displayid: null,
            endhour: 24,
            id: 12806680,
            imagefilename: null,
            images: null,
            maxcalories: null,
            maximumbasketquantity: "",
            maximumquantity: null,
            menuitemlabels: [],
            metadata: null,
            minimumbasketquantity: "",
            minimumquantity: "1",
            name: "Avocado & Roasted Pepper Bowl",
            quantityincrement: "1",
            shortdescription: "Avocado & Pepper Bowl",
            starthour: 0,
            unavailablehandoffmodes: [],
          },
        ],
      },
    ],
    imagepath: null,
  },
]

// Options from products
export const productOptions = [
  {
    id: "12806676",
    optiongroups: [
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        chainmodifierid: 753264,
        choicequantityincrement: "1",
        description: "1/2 Chicken",
        explanationtext: "",
        hidechoicecost: false,
        id: 205964060,
        mandatory: true,
        maxaggregatequantity: null,
        maxchoicequantity: null,
        maxselects: null,
        metadata: null,
        minaggregatequantity: null,
        minchoicequantity: null,
        minselects: null,
        options: [
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427220,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553751,
            isdefault: true,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "1/2 Chicken",
          },
        ],
        parentchoiceid: null,
        supportschoicequantities: false,
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        chainmodifierid: 753277,
        choicequantityincrement: "1",
        description: "PERi-ometer",
        explanationtext: "Choose your heat before you eat",
        hidechoicecost: false,
        id: 205964058,
        mandatory: true,
        maxaggregatequantity: null,
        maxchoicequantity: null,
        maxselects: null,
        metadata: null,
        minaggregatequantity: null,
        minchoicequantity: null,
        minselects: null,
        options: [
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427154,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553720,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Plain...ish",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427466,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553722,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Mango & Lime",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427594,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553724,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Lemon & Herb",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427592,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553723,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Medium",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427009,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553719,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Hot",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427365,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553721,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Extra Hot",
          },
        ],
        parentchoiceid: null,
        supportschoicequantities: false,
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        chainmodifierid: 753370,
        choicequantityincrement: "1",
        description: "Side Fries",
        explanationtext: "",
        hidechoicecost: false,
        id: 205964061,
        mandatory: true,
        maxaggregatequantity: null,
        maxchoicequantity: null,
        maxselects: null,
        metadata: null,
        minaggregatequantity: null,
        minchoicequantity: null,
        minselects: null,
        options: [
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427032,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553752,
            isdefault: true,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Fries",
          },
        ],
        parentchoiceid: null,
        supportschoicequantities: false,
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        chainmodifierid: 753360,
        choicequantityincrement: "1",
        description: "Add a drink?",
        explanationtext: "",
        hidechoicecost: false,
        id: 205964059,
        mandatory: false,
        maxaggregatequantity: null,
        maxchoicequantity: null,
        maxselects: null,
        metadata: null,
        minaggregatequantity: null,
        minchoicequantity: null,
        minselects: null,
        options: [
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427088,
            children: false,
            cost: 3.29,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553728,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Mexican Coke",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427658,
            children: false,
            cost: 2.79,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553745,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Coke Classic",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427305,
            children: false,
            cost: 2.79,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553734,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Diet Coke",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3426983,
            children: false,
            cost: 2.79,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553726,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Coke Zero",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427186,
            children: false,
            cost: 2.79,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553732,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Sprite",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427540,
            children: false,
            cost: 3.29,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553742,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Smartwater",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427560,
            children: false,
            cost: 2.79,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553743,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Honest Tea - Honey Green BTL",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427155,
            children: false,
            cost: 3.29,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553730,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Bundaberg Ginger Beer",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427359,
            children: false,
            cost: 2.59,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553739,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "LaCroix",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427776,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553749,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Pepsi",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427311,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553736,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Mountain Dew",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427306,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553735,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Pepsi Zero Sugar",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427334,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553738,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Diet Dr. Pepper",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427096,
            children: false,
            cost: 3.39,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553729,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Pineapple Lemonade",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: true,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427368,
            children: false,
            cost: 3.39,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553740,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Blood Orange & Mango Lemonade",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427605,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553744,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Bubly Blackberry",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427450,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553741,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Bubly Cherry",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427193,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553733,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Life WTR",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427163,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553731,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Orange Crush",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427023,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553727,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Dole Lemonade",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427312,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553737,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Lipton Pure Leaf (Sweet)",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427766,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553748,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Lipton Pure Leaf (Unsweet)",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3426966,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553725,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Voss Still Water",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427713,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553746,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Perrier Sparkling Water",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427814,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553750,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Aquafina",
          },
          {
            adjustsparentcalories: false,
            adjustsparentprice: false,
            availability: {
              always: true,
              description: null,
              enddate: null,
              now: true,
              startdate: null,
            },
            basecalories: null,
            caloriesseparator: null,
            chainoptionid: 3427716,
            children: false,
            cost: 0.0,
            costoverridelabel: null,
            displayid: null,
            fields: null,
            id: 1162553747,
            isdefault: false,
            maxcalories: null,
            menuitemlabels: [],
            metadata: null,
            modifiers: null,
            name: "Bruce Cost Ginger Ale-Pomegranate",
          },
        ],
        parentchoiceid: null,
        supportschoicequantities: false,
      },
    ],
  },
]

// Locations Filters
export const filterLocations = [
  {
    items: [
      {
        name: "Hours",
        id: "filter-hours",
        items: [
          {
            name: "Open Now",
            value: "open now",
            iconType: "alarm",
          },
        ],
      },
      {
        name: "Order Options",
        id: "filter-options",
        items: [
          {
            name: "Catering",
            value: "cateringUrl",
            iconType: "catering",
          },
          {
            name: "Takeout",
            value: "takeout",
            iconType: "shopping-bag",
          },
          {
            name: "Order at Table",
            value: "order at table",
            iconType: "article",
          },
          {
            name: "Delivery",
            value: "delivery",
            iconType: "transfer",
          },
          {
            name: "Dine In",
            value: "dine in",
            iconType: "dine",
          },
          {
            name: "Patio",
            value: "patio",
            iconType: "patio",
          },
          {
            name: "Curbside Pickup",
            value: "curbside pickup",
            iconType: "pickup",
          },
          {
            name: "Free Parking",
            value: "free parking",
            iconType: "parking",
          },
        ],
      },
    ],
  },
]

// Locations Filters
export const filterLocationsCA = [
  {
    items: [
      {
        name: "Hours",
        id: "filter-hours",
        items: [
          {
            name: "Open Now",
            value: "open now",
            iconType: "alarm",
          },
        ],
      },
      {
        name: "Order Options",
        id: "filter-options",
        items: [
          {
            name: "Catering",
            value: "cateringUrl",
            iconType: "catering",
          },
          {
            name: "Takeout",
            value: "takeout",
            iconType: "shopping-bag",
          },
          {
            name: "Order at Table",
            value: "order at table",
            iconType: "article",
          },
          {
            name: "Delivery",
            value: "delivery",
            iconType: "transfer",
          },
          {
            name: "Dine In",
            value: "dine in",
            iconType: "dine",
          },
          {
            name: "Patio",
            value: "patio",
            iconType: "patio",
          },
          {
            name: "Curbside Pickup",
            value: "curbside pickup",
            iconType: "pickup",
          },
          {
            name: "Free Parking",
            value: "free parking",
            iconType: "parking",
          },
          {
            name: "Peri-Perks",
            value: "peri-perks",
            iconType: "dine",
          },
        ],
      },
    ],
  },
]

// Delivery Options
export const deliveryOptions = [
  {
    id: "delivery-ubereats",
    name: "UberEats",
    value: "delivery-ubereats",
    url: "/",
  },
  {
    id: "delivery-doordash",
    name: "Doordash",
    value: "delivery-doordash",
    url: "/",
  },
]

// Main Collection
export const mainCollection = [
  {
    description:
      "What we're famous for... Fresh chicken marinated for at least 24 hours in PERi-PERi sauce flame-grilled to order.",
    id: 41179,
    images: null,
    metadata: null,
    name: "PERi-PERi Chicken",
    products: [
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "423",
        caloriesseparator: "-",
        chainproductid: 298039,
        cost: 0.0,
        costoverridelabel: null,
        description:
          "First time at Nando's? Look no further than our signature 1/2 chicken. (423 cal)",
        displayid: null,
        endhour: 24,
        id: 12806682,
        imagefilename: null,
        images: null,
        maxcalories: "1623",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "1/2 Chicken",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "226",
        caloriesseparator: "-",
        chainproductid: 297994,
        cost: 0.0,
        costoverridelabel: null,
        description: "Double your favorite cut. (326 cal)",
        displayid: null,
        endhour: 24,
        id: 12806684,
        imagefilename: null,
        images: null,
        maxcalories: "1426",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "Two 1/4 Chicken Legs",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "260",
        caloriesseparator: "-",
        chainproductid: 297989,
        cost: 0.0,
        costoverridelabel: null,
        description: "The succulent and saucy bits. (260 cal)",
        displayid: null,
        endhour: 24,
        id: 12806686,
        imagefilename: null,
        images: null,
        maxcalories: "1460",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "1/4 Chicken Breast",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "163",
        caloriesseparator: "-",
        chainproductid: 297923,
        cost: 0.0,
        costoverridelabel: null,
        description: "For a little dark 'n' delicious. (163 cal)",
        displayid: null,
        endhour: 24,
        id: 12806688,
        imagefilename: null,
        images: null,
        maxcalories: "1363",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "1/4 Chicken Leg",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "260",
        caloriesseparator: "-",
        chainproductid: 298102,
        cost: 0.0,
        costoverridelabel: null,
        description:
          "Butterflied whole chicken breast with crispy skin. No bone about it! (429 cal)",
        displayid: null,
        endhour: 24,
        id: 12806690,
        imagefilename: null,
        images: null,
        maxcalories: "1460",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "Boneless Breast",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "175",
        caloriesseparator: "-",
        chainproductid: 298005,
        cost: 0.0,
        costoverridelabel: null,
        description:
          "Dig into 3 boneless thighs, flame-grilled with skin. (418 cal)",
        displayid: null,
        endhour: 24,
        id: 12806692,
        imagefilename: null,
        images: null,
        maxcalories: "1275",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "3 Chicken Thighs",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "510",
        caloriesseparator: "-",
        chainproductid: 297808,
        cost: 0.0,
        costoverridelabel: null,
        description: "Two of the succulent and saucy bits. (520 cal)",
        displayid: null,
        endhour: 24,
        id: 12806694,
        imagefilename: null,
        images: null,
        maxcalories: "1710",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "Two 1/4 Chicken Breasts",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "809",
        caloriesseparator: "-",
        chainproductid: 298085,
        cost: 0.0,
        costoverridelabel: null,
        description:
          "Twelve of our sauciest treats - tender and spicy. (806 cal)",
        displayid: null,
        endhour: 24,
        id: 12806696,
        imagefilename: null,
        images: null,
        maxcalories: "2009",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "12 Wings",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "310",
        caloriesseparator: "-",
        chainproductid: 297889,
        cost: 0.0,
        costoverridelabel: null,
        description:
          "Two skewers of skin-on-thighs, red pepper, and onions. (310 cal)",
        displayid: null,
        endhour: 24,
        id: 12806698,
        imagefilename: null,
        images: null,
        maxcalories: "1510",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "Chicken Thigh Skewers",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
      {
        availability: {
          always: true,
          description: null,
          enddate: null,
          now: true,
          startdate: null,
        },
        basecalories: "413",
        caloriesseparator: "-",
        chainproductid: 298029,
        cost: 0.0,
        costoverridelabel: null,
        description: "Rich, tasty livers that melt in your mouth. (413 cal)",
        displayid: null,
        endhour: 24,
        id: 12806700,
        imagefilename: null,
        images: null,
        maxcalories: "1613",
        maximumbasketquantity: "",
        maximumquantity: null,
        menuitemlabels: [],
        metadata: null,
        minimumbasketquantity: "",
        minimumquantity: "1",
        name: "Livers",
        quantityincrement: "1",
        shortdescription: "",
        starthour: 0,
        unavailablehandoffmodes: [],
      },
    ],
  },
]

// Columns with content
export const columnsContent = [
  {
    title: "It’s the best thing to happen to chicken since PERi-PERi.",
    content: "Get your favorites for free.",
    columns: [
      {
        title: "500 points",
        subtitle: "$5",
        content: "Garlic sticks, anyone?",
      },
      {
        title: "1000 points",
        subtitle: "$10",
        content: "Wrap it up, get bowled over. It’s up to you.",
      },
      {
        title: "1500 points",
        subtitle: "$15",
        content: "Make a meal of it with an essential side.",
      },
      {
        title: "2500 points",
        subtitle: "$25",
        content: "Double up. It’s date night!",
      },
    ],
  },
]

// Columns with content
export const columnsContentImage = [
  {
    title: "How it works?",
    content: "Get your favorites for free.",
    columns: [
      {
        title: "1. Join",
        subtitle: "$5",
        content:
          "The hardest part about earning the PERi-Perks rewards program is signing up. Download the app or sign up online. It’s that simple. Get 250 points just for joining.",
      },
      {
        title: "2. Earn",
        subtitle: "$10",
        content:
          "Earn 10 POINTS for every $1 SPENT. Get a $5 OFF for every 500 POINTS. Points add up for discounts that can be applied to drinks, sides, entrees and more.",
      },
      {
        title: "3. Redeem",
        subtitle: "$15",
        content:
          "Redeem points for discounts on your favorite food items online, in app, or in any of Nando’s find. The more you eat, the more you earn.",
      },
      {
        title: "4. Enjoy",
        subtitle: "$25",
        content:
          "Enjoy all of the perks that come with loyalty—including a special birthday treat, exclusive offers, early access to new menu items, and more.",
      },
    ],
  },
]

// Collage content with image
export const CollageContent = [
  {
    items: [
      {
        title: "Feature 1",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        videoID: "1hHSH9sJUEo",
        videoSrc: "youtube",
        imageIcon: {
          url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
          width: 500,
          height: 500,
        },
        buttonsCollection: [
          {
            items: [
              {
                label: "Learn More",
                externalUrl: "/",
              },
            ],
          },
        ],
        items: [
          {
            title: "Feature 1",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 2",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 3",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Feature 2",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        videoID: "1hHSH9sJUEo",
        videoSrc: "youtube",
        imageIcon: {
          url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
          width: 500,
          height: 500,
        },
        buttonsCollection: [
          {
            items: [
              {
                label: "Learn More",
                externalUrl: "/",
              },
            ],
          },
        ],
        items: [
          {
            title: "Feature 1",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 2",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 3",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Feature 3",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        videoID: "1hHSH9sJUEo",
        videoSrc: "youtube",
        imageIcon: {
          url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
          width: 500,
          height: 500,
        },
        buttonsCollection: [
          {
            items: [
              {
                label: "Learn More",
                externalUrl: "/",
              },
            ],
          },
        ],
        items: [
          {
            title: "Feature 1",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 2",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
          {
            title: "Feature 3",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            videoID: "1hHSH9sJUEo",
            videoSrc: "youtube",
            imageIcon: {
              url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
              width: 500,
              height: 500,
            },
            buttonsCollection: [
              {
                items: [
                  {
                    label: "Learn More",
                    externalUrl: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

// List Content
export const ListContent = [
  {
    backgroundColor: "#FAF7F1",
    header: "List of find or menu items",
    intro:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    items: [
      {
        title: "Restaurant 1",
        url: "/",
      },
      {
        title: "List 2",
        url: "/",
      },
      {
        title: "Restaurant 3",
        url: "/",
      },
      {
        title: "Menu Item 1",
        url: "/",
      },
      {
        title: "List 2",
        url: "/",
      },
      {
        title: "List 3",
        url: "/",
      },
      {
        title: "Menu Item 1",
        url: "/",
      },
      {
        title: "List 2",
        url: "/",
      },
      {
        title: "List 3",
        url: "/",
      },
      {
        title: "List 1",
        url: "/",
      },
      {
        title: "List 2",
        url: "/",
      },
      {
        title: "Menu Item 3",
        url: "/",
      },
    ],
    cta: [
      {
        label: "Learn More",
        externalUrl: "/",
      },
      {
        label: "Learn More",
        externalUrl: "/",
      },
    ],
  },
]

// Locations Map Content
export const LocationsMapContent = [
  {
    backgroundColor: "#FFFFFF",
    header: "Find your nearest Nando’s",
    intro:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    cta: [
      {
        label: "Learn More",
        externalUrl: "/",
      },
      {
        label: "Learn More",
        externalUrl: "/",
      },
    ],
  },
]

// Rewards Menu
export const RewardsMenu = [
  {
    items: [
      {
        name: "My Account",
        slugUrl: "account-balance",
        itemsCollection: {
          items: [
            {
              name: "Account Balance",
              slugUrl: "account-balance"
            },
            {
              name: "Earn Points",
              slugUrl: "earn-points"
            },
            {
              name: "Redeem Rewards",
              slugUrl: "redeem-rewards"
            },
            {
              name: "Profile",
              slugUrl: "profile"
            }
          ]
        }
      }
    ]
  }
];

// Main Menu navigation
export const RewardsNavigation = [
  {
    name: "Account Balance and History",
    url: "account-balance",
    id: "account-balance",
  },
  {
    name: "Earn",
    url: "earn-points",
    id: "earn",
  },
  {
    name: "Rewards & Offers",
    url: "redeem-rewards",
    id: "rewards-offers",
  },
  {
    name: "Profile",
    url: "profile",
    id: "profile",
  },
]

// Points Rewards Information
export const PointsRewarsInformation = [
  {
    coverPosition: "Left",
    backgroundColor: "#FFFFFF",
    contentAlign: "Center",
    pointsLeft: "250",
    totalPoints: "750",
    goalPoints: "1000",
    buttons: [
      {
        label: "Earn",
        externalUrl: "/rewards/earn-points",
      },
      {
        label: "Redeem",
        externalUrl: "/rewards/redeem-rewards",
      },
    ],
  },
]

// Points Rewards Information
export const PromoRewardsInformation = [
  {
    header: "PERi-PERi Promotion",
    coverPosition: "Left",
    backgroundColor: "#FFFFFF",
    contentAlign: "Left",
    pointsLeft: "250",
    totalPoints: "750",
    goalPoints: "1000",
    buttons: [
      {
        label: "Sign Up",
        externalUrl: "/rewards/signup",
      },
    ],
  },
]

// Account History Information
export const AccountHistoryInfo = [
  {
    header: "Account History",
    history: [
      {
        date: "10/21/2021",
        action: "Rewards Redeemed",
        pointsEarned: "0",
        description:
          "500 point = $5 off redeemed using redemption code 5471303 (Available)",
        points: 750,
        items: 0,
      },
      {
        date: "10/15/2021",
        action: "Bonus Points Earned",
        pointsEarned: "+750 points",
        description: "750 Bonus points earned-Support Activity",
        points: 750,
        items: 0,
      },
      {
        date: "04/21/2020",
        action: "Bonus Points Earned",
        pointsEarned: "+750 points",
        description: "750 Bonus points earned-Support Activity",
        points: 750,
        items: 0,
      },
      {
        date: "01/11/2020",
        action: "Bonus Points Earned",
        pointsEarned: "+750 points",
        description: "750 Bonus points earned-Support Activity",
        points: 750,
        items: 0,
      },
      {
        date: "02/14/2019",
        action: "Bonus Points Earned",
        pointsEarned: "+750 points",
        description: "750 Bonus points earned-Support Activity",
        points: 750,
        items: 0,
      },
    ],
  },
]

// Prizes
export const RewardsPrizes = [
  {
    items: [
      {
        title: "On points 500",
        points: "500",
        prize: "500 point = $5 off",
        description: "(- side/drink)",
        buttons: [
          {
            label: "Redeem in store",
            externalUrl: "/rewards", 
          },
        ],
      },
      {
        title: "On points 1000",
        points: "1000",
        prize: "1000 point = $5 off",
        description: "(- side/drink)",
        buttons: [
          {
            label: "Redeem in store",
            externalUrl: "/rewards",
          },
        ],
      },
      {
        title: "On points 1500",
        points: "1500",
        prize: "1500 point = $5 off",
        description: "(- side/drink)",
        buttons: [
          {
            label: "Redeem in store",
            externalUrl: "/rewards",
          },
        ],
      },
      {
        title: "On points 2000",
        points: "2000",
        prize: "2000 point = $5 off",
        description: "(- side/drink)",
        buttons: [
          {
            label: "Redeem in store",
            externalUrl: "/rewards",
          },
        ],
      },
      {
        title: "On points 2500",
        points: "2500",
        prize: "2500 point = $5 off",
        description: "(- side/drink)",
        buttons: [
          {
            label: "Redeem in store",
            externalUrl: "/rewards",
          },
        ],
      },
    ],
  },
]

// Recipes
export const RecipesTest = [
  {
    items: [
      {
        title: "Recipe 1",
      },
      {
        title: "Recipe 2",
      },
      {
        title: "Recipe 3",
      },
      {
        title: "Recipe 4",
      },
      {
        title: "Recipe 5",
      },
      {
        title: "Recipe 6",
      },
      {
        title: "Recipe 7",
      },
      {
        title: "Recipe 8",
      },
      {
        title: "Recipe 9",
      },
      {
        title: "Recipe 10",
      },
      {
        title: "Recipe 11",
      },
      {
        title: "Recipe 12",
      },
    ],
  },
]

// Recipes Tags
export const RecipesTags = [
  {
    items: [
      {
        name: "Breakfast",
        value: "breackfast",
      },
      {
        name: "Quick Lunch",
        value: "quick-lunch",
      },
      {
        name: "Dinner",
        value: "dinner",
      },
      {
        name: "Vegetarian",
        value: "vegetarian",
      },
      {
        name: "Grilling",
        value: "grilling",
      },
      {
        name: "Entertaining",
        value: "entertaining",
      },
      {
        name: "Sides & Dips",
        value: "sides-dips",
      },
    ],
  },
]

// Collage content with image
export const ImageVideoContent = [
  {
    title: "Feature 1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    videoID: "1hHSH9sJUEo",
    videoSrc: "youtube",
    image: {
      url: "https://images.ctfassets.net/xlzobf9ybr6d/1wnuMpxUyEPT2Ct0N268Bq/8bff28f80e19a05db92aa8fd8cb5be73/Holiday_Banner_2.jpg",
    },
  },
]